



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Observer } from 'mobx-vue'

@Observer
@Component({})
export default class PoolLogo extends Vue {
  @Prop() img: any
  @Prop({ default: 42 }) logoSize: any
  @Prop({ default: 56 }) outlineSize: any
}
