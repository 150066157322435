var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pool-logo pa-1 d-flex justify-center align-center",style:('width:' +
    _vm.outlineSize +
    'px; ' +
    'height:' +
    _vm.outlineSize +
    'px; ' +
    'border-radius: ' +
    Math.floor(_vm.outlineSize / 2) +
    'px')},[_c('app-logo',{attrs:{"width":_vm.logoSize,"height":_vm.logoSize,"avatar":_vm.img}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }